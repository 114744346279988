import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/login/pages/LoginView.vue')
  },
  {
    path: '/desktop',
    name: 'DesktopView',
    component: () => import('../views/desktop/pages/DesktopView.vue')
  },
  {
    path: '/game',
    name: 'GameView',
    beforeEnter() {
      window.location.href = '/web-desktop/index.html'; // Redirect to Cocos game's index.html
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
